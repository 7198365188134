import { HttpErrorResponse } from '@angular/common/http';
import { BusinessCardSettings } from '@features/user-business-cards/interfaces/business-card-settings.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { BusinessCardDesign } from '@shared/business-cards/interfaces/business-card-design.interface';

export const UserBusinessCardsEditPageActions = createActionGroup({
    source: 'User Business Cards Edit Page',
    events: {
        'Enter Page': emptyProps(),
        'Leave Page': emptyProps(),
        'Save My Card': props<{ payload: any }>(),
        'Save My Card Success': props<{ design: BusinessCardDesign }>(),
        'Save My Card Failure': props<{ error: HttpErrorResponse }>(),
        'Get App Business Card Settings': emptyProps(),
        'Get App Business Card Settings Success': props<{ settings: BusinessCardSettings }>(),
        'Get App Business Card Settings Failure': props<{ error: HttpErrorResponse }>(),
        'Get App Business Card Design': emptyProps(),
        'Get App Business Card Design Success': props<{ design: BusinessCardDesign }>(),
        'Get App Business Card Design Failure': props<{ error: HttpErrorResponse }>()
    }
});
